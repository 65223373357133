<template>
  <Layout class="E-OrderList">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="orderId">
          <el-input
            v-model="formData.orderId"
            clearable
            placeholder="订单号"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="userId">
          <el-input
            v-model="formData.userId"
            clearable
            placeholder="用户ID"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="CDKEY">
          <el-input
            v-model="formData.cdkey"
            clearable
            placeholder="CDKEY"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.orderStatus"
            placeholder="发货状态"
            clearable
          >
            <el-option
              v-for="(item, index) in orderStatusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
      @sort-change="handleSortTable"
    >
      <el-table-column
        label="订单编号"
        prop="orderIdStr"
        width="170px"
      />
      <el-table-column
        label="游戏名称"
        prop="gameName"
        width="170px"
      />
      <el-table-column
        label="用户ID"
        prop="userIdStr"
      />
      <el-table-column
        label="购买数量"
        prop="cdkNum"
      />
      <el-table-column
        label="交易金额"
        prop="actualPayment"
      />
      <el-table-column
        label="创建时间（GMT +0）"
        prop="createTime"
        sortable="custom"
        width="180px"
      />
      <el-table-column
        label="支付完成时间"
        prop="payTime"
        width="160px"
      >
        <template v-slot="{row}">
          {{ row.payTime === '0000-00-00 00:00:00' ? '/': row.payTime }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="orderStatus"
        sortable="custom"
        width="160px"
      >
        <template v-slot="{row}">
          <i
            class="status-dot"
            :class="{
              undelivered: row.orderStatus === 200,
              delivered: row.orderStatus === 300,
              tobepaid: row.orderStatus === 100,
              failed: row.orderStatus === 400 || row.orderStatus === 500
            }"
          />
          {{ deliveryStatusMap[row.orderStatus] || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        label="CDKEY"
        prop="cdkInfo"
      />
      <el-table-column
        label="操作"
        fixed="right"
        width="150px"
      >
        <template #default="{row}">
          <el-select
            :disabled="row.loading"
            :value="row.orderStatus"
            @change="handleUpdateStatus($event, row)"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  queryOrderList,
  updateStatus
} from '@/api/mallv2.js'
export default {
  name: 'OrderList',
  data () {
    return {
      orderStatusList: [
        { name: '待支付', value: 100 },
        { name: '已支付待发货', value: 200 },
        { name: '已发货', value: 300 },
        { name: '服务端取消', value: 400 },
        { name: '支付失败', value: 500 }
      ],
      deliveryStatusMap: {
        100: '待支付',
        200: '已支付待发货',
        300: '已发货',
        400: '服务端取消',
        500: '支付失败'
      },
      formData: {
        sortKey: '',
        sortType: '',
        orderStatus: '',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      }
    }
  },
  created () {
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      queryOrderList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.orderList || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSortTable ({ prop, order }) {
      this.formData.sortKey = prop
      const sortType = order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      if (sortType) {
        this.queryDataList()
      }
    },
    handleUpdateStatus (status, row) {
      const localStatusName = this.deliveryStatusMap[row.orderStatus]
      const nextStatusName = this.deliveryStatusMap[status]

      const TIPS = `将由 '${localStatusName}' 改为 '${nextStatusName}' , 是否继续?`
      this.$set(row, 'loading', true)
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          updateStatus({
            orderId: row.orderIdStr,
            orderStatus: status
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.$set(row, 'orderStatus', status)
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
        .catch((err) => {
          this.$set(row, 'loading', false)
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="less">
.E-OrderList {
  .status-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: gray;
    margin-right: 10px;
    &.undelivered{
      background: #198FFC;
    }
    &.delivered{
      background: #51C41B;
    }
    &.tobepaid{
      background: #FF9800;
    }
    &.failed{
      background: #FF5C61;
    }
  }
}
</style>
